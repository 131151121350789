const initState = {
  authError: null,
  authMessage: null,
  authPopUp: false,
  authErrorPopUp: false,
  authLoading: null,
  editingUser: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null,
      };
    case 'SIGNOUT_SUCCESS':
      //console.log('signout success');
      return state;
    case 'NEW_USER_CREATED':
      //console.log('New User has been created');
      return {
        ...state,
        authError: null,
      };
    case 'NEW_USER_ERROR':
      //console.log('User was not created', action.err.message);
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'USER_DB_INFO_UPDATED':
      //console.log('Users information has been updated');
      return {
        ...state,
        authError: null,
      };
    case 'USER_DB_INFO_ERROR':
      //console.log('Users information has not been udpated');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'USER_EMAIL_HAS_BEEN_UPDATED':
      //console.log('Users login email has been updated in the database and online');
      return {
        ...state,
        authError: null,
        authMessage: 'Your email has been updated',
        authPopUp: true,
      };
    case 'USER_EMAIL_NOT_UPDATED':
      //console.log('Users email has NOT been updated in the database');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'USER_ID_EMAIL_NOT_UPDATED':
      //console.log('Users Login email has NOT been updated');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'CHANGE_EMAIL_REAUTHENTICATION_ERROR':
      //console.log('User Failed to login while attempting to change their login Email');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'USER_PASSWORD_WAS_NOT_UPDATED':
      console.log('Users Password was not updated');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR':
      //console.log('Original Password was not correct');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'USER_PASSWORD_WAS_UPDATED':
      //console.log('Users password has been updated!');
      return {
        ...state,
        authMessage: 'Password has been updated',
        authPopUp: true,
      };
    case 'USER_EMAIL_RESET_SENT':
      //console.log('An Email has been sent to the user to reset their password.');
      return {
        ...state,
        authMessage: 'Password reset email has been sent',
        authPopUp: true,
      };
    case 'USER_EMAIL_RESET_NOT_SENT':
      //console.log('Failed to send the user a reset password email');
      return {
        ...state,
        authErrorPopUp: true,
        authError: action.err.message,
      };
    case 'CLOSE_AUTH_POPUP':
      return {
        ...state,
        authError: null,
        authMessage: null,
        authPopUp: false,
        authErrorPopUp: false,
      };

    case 'STARTING_USER_CREATION':
      return {
        ...state,
        authMessage: 'Adding a user',
        authPopUp: true,
        authLoading: true,
      };
    case 'USER_WAS_CREATED':
      return {
        ...state,
        authMessage: 'User was added',
        authPopUp: true,
        //authError: null,
        authLoading: false,
      };

    case 'FAILED_TO_CREATE_USER':
      console.log('this is my message', action.result);

      return {
        ...state,
        authMessage: null,
        authError: action.result.data.message,
        authErrorPopUp: true,
      };
    case 'LOADING_INFO_FOR_THIS_USER':
      return {
        ...state,
        editingUser: action.userID,
      };
    default:
      return state;
  }
};

export default authReducer;
