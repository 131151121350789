import * as ROLES from '../constants/roles';

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.analytics().logEvent('login', { method: 'Email' });

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const createNewUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    console.log('this is my new user', newUser);
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        const userID = resp.user.uid;
        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            fullName: newUser.firstName + ' ' + newUser.lastname,
            initials: newUser.firstname[0] + newUser.lastname[0],
            company: newUser.company,
            email: newUser.email,
            role: ROLES.COMPANY,
            userAdded: new Date(),
          })
          .then((resp) => {
            //Update the company with the new users
            if (newUser.role === ROLES.COMPANY) {
              return firestore
                .collection('companies')
                .doc(newUser.company)
                .update({
                  owner: firestore.FieldValue.arrayUnion(userID),
                })
                .then(() => {
                  dispatch({ type: 'NEW_USER_CREATED' });
                });
            } else if (newUser.role === ROLES.STAFF) {
              return firestore
                .collection('companies')
                .doc(newUser.company)
                .update({
                  staff: firestore.FieldValue.arrayUnion(userID),
                })
                .then(() => {
                  dispatch({ type: 'NEW_USER_CREATED' });
                });
            } else {
              dispatch({ type: 'NEW_USER_CREATED' });
            }
          });
      })
      .catch((err) => {
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const updateUserInfoDB = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let companies = [];

    for (let i = 0; i < user.companyCount; i++) {
      companies.push(user['company' + i + 'ID']);
    }
    firestore
      .collection('users')
      .doc(user.id)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName,
        initials: user.firstName[0] + user.lastName[0],
        company: companies,
        role: user.role,
      })
      .then(() => {
        dispatch({ type: 'USER_DB_INFO_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};

export const updateUserEmailInfo = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.originalEmail, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updateEmail(user.email)
          .then(() => {
            firestore
              .collection('users')
              .doc(user.userId)
              .update({
                email: user.email,
              })
              .then(() => {
                dispatch({ type: 'USER_EMAIL_HAS_BEEN_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'USER_EMAIL_NOT_UPDATED', err });
              });
          })
          .catch((err) => {
            dispatch({ type: 'USER_ID_EMAIL_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_EMAIL_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const updateUserPassword = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(user.newPassword)
          .then(() => {
            dispatch({ type: 'USER_PASSWORD_WAS_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'USER_PASSWORD_WAS_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const sendEmailPasswordReset = (user) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    //console.log('this is my user email', user);

    firebase
      .auth()
      .sendPasswordResetEmail(user)
      .then(() => {
        dispatch({ type: 'USER_EMAIL_RESET_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_EMAIL_RESET_NOT_SENT', err });
      });
  };
};

export const createNewOwner = ({ user, company }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((resp) => {
        const userID = resp.user.uid;

        //console.log('this is my resp', resp);

        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: user.firstName + ' ' + user.lastName,
            initials: user.firstName[0] + user.lastName[0],
            company: company[0].id,
            email: user.email,
            role: 'company',
            userAdded: new Date(),
          })
          .then(() => {
            //Update the company with the new users

            return firestore
              .collection('companies')
              .doc(company[0].id)
              .update({
                owner: firestore.FieldValue.arrayUnion(userID),
              })
              .then(() => {
                firebase.analytics().logEvent('business_new_owner', { company: company[0].id, user: resp.user.uid });
                dispatch({ type: 'NEW_USER_CREATED' });
              })
              .catch((err) => {
                console.log('I could not be added to the company', err);
                dispatch({ type: 'ERROR_ADDING_OWNER_TO_COMPANY' });
              });
          })
          .catch((err) => {
            console.log('there was an error creating the user', err);
          });
      })
      .catch((err) => {
        console.log('hur dur', err);
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const closeAuthPopUp = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_AUTH_POPUP' });
  };
};

export const addUserCloudFunction = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    console.log('lets create a new users!');
    const sendUser = firebase.functions().httpsCallable('addUserFromClinic');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const registerUserEmail = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my user', user);

    firestore
      .collection('mail')
      .doc()
      .set({
        //cc: 'ccressman@gmail.com',
        cc: 'margaret.kaczanowski@solta.com',
        //cc: 'ben@metastudios.co',
        message: {
          subject: `A new user wants to be added to the Solta eLearning Platform`,
          text: `Hi Amanda, ${user.regName} doesn't have an account on the Solta Clinic platform but they have sent a request to be added. Can you create a new account with the following infromation: Email: ${user.regEmail}, Name:${user.regName}, Company: ${user.regCompany}, City: ${user.regCity}, Prov: ${user.regProv} -- Solta Clinic Platform`,
          html: `Hi Amanda, ${user.regName} doesn't have an account on the Solta Clinic platform but they have sent a request to be added.<br/><br/> Can you create a new account with the following infromation: <br/><br/><b>Email:</b> ${user.regEmail},<br/> <b>Name:</b>${user.regName},<br/> <b>Company:</b> ${user.regCompany}<br/>   <b>City:</b> ${user.regCity}<br/> <b>Prov:</b> ${user.regProv}  <br/><br/> -- Solta Clinic Platform`,
        },
      })
      .then((resp) => {
        dispatch({ type: 'SENT_REG_EMAIL_OFF' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_REG_EMAIL_OFF', err });
      });
  };
};

export const editingThisUser = (userID) => {
  return (dispatch) => {
    dispatch({ type: 'LOADING_INFO_FOR_THIS_USER', userID });
  };
};

export const userAgreedToTerms = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('users')
      .doc(user)
      .update({
        agreeToTerms: new Date(),
      })
      .then(() => {
        dispatch({ type: 'USER_AGREED_TO_TERMS' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_USER_AGREED_TO_TERMS', err });
      });
  };
};
