import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut, editingThisUser } from '../../actions/authActions';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
//import BallotIcon from '@material-ui/icons/Ballot';
import HomeIcon from '@material-ui/icons/Home';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
//import LoadingBlock from '../Loading';
import GroupIcon from '@material-ui/icons/Group';

const INITIAL_STATE = {
  //loading: false,
  menuDrawer: false,
  redirect: false,
  redirectLink: null,
};

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }
  //console.log(props)

  handleMenu = (event) => {
    this.setState({
      anchorEll: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  openDrawer = () => {
    this.setState({
      menuDrawer: true,
    });
  };

  toggleDrawer = () => {
    this.setState({
      menuDrawer: false,
    });
  };

  clickLink = ({ link }) => {
    //console.log('this is my link', link);
    this.setState({
      menuDrawer: false,
      redirectLink: link,
      redirect: true,
    });
  };

  sendUserInfo = (userID) => {
    this.props.editingThisUser(userID);
  };

  render() {
    const { menuDrawer, redirect, redirectLink } = this.state;
    const { auth, t, classes, profile } = this.props;

    const signOutLinks = auth.uid ? (
      <Button type='button' onClick={this.props.signOut} variant='contained'>
        {t('navigation.signOutButton')}
      </Button>
    ) : (
      <Button type='button' onClick={this.handleClose} variant='contained' component={Link} to={ROUTES.LOGIN}>
        {t('navigation.signInButton')}
      </Button>
    );
    //console.log('logo props profile', props.profile);

    //console.log('logo props profile', profile);
    return (
      <div>
        {redirect ? <Redirect push to={redirectLink} /> : null}
        <AppBar position='static'>
          <Toolbar className={`nav-wrapper ${classes.soltaBackground}`}>
            {!profile.isEmpty ? (
              <div>
                {/* <IconButton aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={this.openDrawer} color='inherit'>
                  <MenuIcon />
                </IconButton> */}

                <SwipeableDrawer anchor='left' open={menuDrawer} onClose={this.toggleDrawer} onOpen={this.openDrawer}>
                  <List className={classes.list}>
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.LANDING })}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('menu.home')} />
                    </ListItem>
                    <Divider />

                    {/* <ListItem button onClick={() => this.clickLink({ link: ROUTES.COURSES })}>
                      <ListItemIcon>
                        <LibraryBooksIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('menu.courses')} />
                    </ListItem>
                    <Divider /> /*}

                    {/*   <ListItem button onClick={() => this.clickLink({ link: ROUTES.RESOURCE_LIBRARY })}>
                      <ListItemIcon>
                        <FolderSpecialIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('menu.resources')} />
                    </ListItem>
                    <Divider /> */}

                    {/* <ListItem button onClick={() => this.clickLink({ link: ROUTES.COMPANY_FORM })}>
                      <ListItemIcon>
                        <FolderSpecialIcon />
                      </ListItemIcon>
                      <ListItemText primary="Company Form" />
                    </ListItem>
                    <Divider /> */}
                    {profile.role !== ROLES.STAFF ? (
                      <div>
                        <ListItem button onClick={() => this.clickLink({ link: ROUTES.STAFF })}>
                          <ListItemIcon>
                            <GroupIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('menu.staff')} />
                        </ListItem>
                        <Divider />
                        {/*  <ListItem button onClick={() => this.clickLink({ link: ROUTES.CERTS })}>
                          <ListItemIcon>
                            <BallotIcon />
                          </ListItemIcon>
                          <ListItemText primary='Clinical Forms' />
                        </ListItem>
                        <Divider /> */}
                      </div>
                    ) : null}

                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.RESOURCE_LIBRARY })}>
                      <ListItemIcon>
                        <FolderSpecialIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('menu.resources')} />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.CLINICAL_LIBRARY })}>
                      <ListItemIcon>
                        <FolderSpecialIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('menu.clinical')} />
                    </ListItem>
                    <Divider />
                  </List>
                </SwipeableDrawer>
              </div>
            ) : null}
            <Typography variant='h6' className={classes.title}>
              <Link to={ROUTES.LANDING} className={classes.white}>
                <Hidden xsDown>{t('navigation.siteName')}</Hidden>
                <Hidden smUp>{t('navigation.siteNameShort')}</Hidden>
              </Link>
            </Typography>
            {!profile.isEmpty ? (
              <Typography variant='h6' className={`${classes.title} ${classes.right}`} onClick={() => this.sendUserInfo(auth.uid)}>
                <Link to={ROUTES.USER + this.props.auth.uid} className={classes.white}>
                  <Hidden xsDown>{this.props.profile.isLoaded && !this.props.profile.isEmpty ? this.props.profile.fullName : ''}</Hidden>
                  <Hidden smUp>{this.props.profile.isLoaded && !this.props.profile.isEmpty ? this.props.profile.initials : ''}</Hidden>
                </Link>
              </Typography>
            ) : null}
            {!profile.isEmpty ? (
              <Typography variant='h6' className={`${classes.right} ${classes.paddingLeft}`}>
                |
              </Typography>
            ) : null}
            <MenuItem onClick={this.handleClose}>{signOutLinks}</MenuItem>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    editingThisUser: (userID) => dispatch(editingThisUser(userID)),
  };
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDisptachToProps))(Navigation);
