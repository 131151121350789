const initState = {
  promoError: null,
  promoMessage: null,
  promoTechDoc: [],
  openMessage: false,
  searchingForPromoUser: false,
  searchMessage: null,
  promoUser: null,
  submitPromoStep: 0,
  promoErrorPopUp: false,
  promoPopUp: false,
};

const promoReducer = (state = initState, action) => {
  switch (action.type) {
    case "DOCTOR_SIGNED_UP_FOR_PROMO":
      //console.log('promo has been added')
      return {
        ...state,
        promoMessage: "You have succesfully signed up for the promo",
        promoPopUp: true,
      };
    case "DOCTOR_FAILED_TO_SIGN_UP_TO_PROMO":
      //console.log('promo has been added')
      return {
        ...state,
        promoError: action.err.message,
        promoErrorPopUp: true,
      };
    case "CLOSE_PROMO_MESSAGE":
      //console.log('promo has been added')
      return {
        ...state,
        promoError: null,
        promoMessage: null,
        openMessage: false,
        promoErrorPopUp: false,
        promoPopUp: false,
      };
    case "STARTING_PROMO_USER_SEARCH":
      return {
        ...state,
        searchingForPromoUser: true,
        searchMessage: null,
      };
    case "NO_USER_FOUND_WITH_PROMO_CODE":
      return {
        ...state,
        searchingForPromoUser: false,
        searchMessage:
          "No one was found with that promo code. Please try another code",
      };

    case "PROMO_CODE_HAS_ALREADY_BEEN_REDEEMED":
      return {
        ...state,
        searchingForPromoUser: false,
        searchMessage: "This promo code has already been redeemed",
        promoPopUp: true,
        promoMessage: "This promo code has already been redeemed",
      };

    case "PROMO_CODE_WAS_FOUND":
      //console.log("this is what we are getting from the database", action.resp);
      return {
        ...state,
        searchingForPromoUser: false,
        searchMessage: null,
        promoUser: action.resp,
      };

    case "PROMO_NEXT_STEP":
      return {
        ...state,
        submitPromoStep: state.submitPromoStep + 1,
      };

    case "PROMO_BACK_STEP":
      return {
        ...state,
        submitPromoStep: state.submitPromoStep - 1,
      };

    case "ERROR_SUBMITTING_PROMO":
      return {
        ...state,
        promoError: action.err.message,
        promoErrorPopUp: true,
      };

    case "ERROR_SUBMITTING_PROMO_FOR_CLINIC":
      return {
        ...state,
        promoError: action.err.message,
        promoErrorPopUp: true,
      };
    case "ERROR_SUBMITTING_PROMO_FOR_CONSUMER":
      return {
        ...state,
        promoError: action.err.message,
        promoErrorPopUp: true,
      };

    case "PROMO_HAS_BEEN_SUBMITTED":
      return {
        ...state,
        submitPromoStep: state.submitPromoStep + 1,
        promoMessage: "The promo code has been submitted",
        promoPopUp: true,
      };

    case "PROMO_START_FRESH":
      return {
        ...state,
        submitPromoStep: 0,
        promoUser: null,
        searchingForPromoUser: false,
        searchMessage: null,
      };
    case "EMAIL_SENT_TO_REP_FOR_PROMO":
      console.log("promo dispatch");
      return {
        ...state,
        promoMessage: "Email has been sent to a sales rep",
        promoPopUp: true,
      };
    case "PROMO_ADS_HAVE_BEEN_UPLOADED":
      return {
        ...state,
        promoMessage: "Your Ads have been uploaded",
        promoPopUp: true,
      };
    default:
      return state;
  }
};

export default promoReducer;
