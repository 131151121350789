/**************************************************************************/
/*  File Name: resourceReducer                                            */
/*  Date:      Mar 15 2021                                                */
/*  Author:    Ben Wakutz                                                 */
/*  Purpose:   Supports the actions for the resource page.                */
/**************************************************************************/

const initState = { 
    resourceError: null,
    resourceMessage: null,
    companyResourceTech: [],
    resourceTechCount: 0,
    resources:[],
    
};

const resourceReducer = ( state = initState, action) =>{
    switch (action.type){
        case 'NO_RESOURCES_FOUND':
            return{
                ...state,
                resourceMessage: "No resources found for your brands."
            };
        case 'RESOURCE_FOUND':
            return{
                ...state,
                resources: [...state.resources, action.resource]
            };
        case 'RESOURCE_ERROR':
            return{
                ...state,
                resourceError: true,
                resourceMessage: action.err.message,
            };
        case 'RESOURCE_TECH_LENGTH':
            //console.log('Length array ->', action)
            return{
                ...state,
                resourceTechCount: action.companyTech.length
            };

        case 'RESOURCE_TECH_FOUND':
            //console.log('Action -> ', action);
            let foundResources = state.companyResourceTech.filter(t => t.id === action.tech.id)
            if(foundResources.length>0){
                return{
                    ...state
                }
            }else{
                return{
                    ...state,
                    companyResourceTech: [ ...state.companyResourceTech, action.tech]
                };
            };
            
        default:
            return state;
    }

};

export default resourceReducer;