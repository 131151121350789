const initState = {
  staffError: null,
  staffMessage: null,
  staffPopUp: false,
  staffErrorPopup: false,
  staff: [],
  loadingStaff: false,
};

const staffReducer = (state = initState, action) => {
  switch (action.type) {
    case 'STAFF_WERE_FOUND':
      return {
        ...state,
        staff: action.staff,
      };
    case 'CLOSE_STAFF_POPUPS':
      return {
        ...state,
        staffPopUp: false,
        staffErrorPopup: false,
        staffError: null,
        staffMessage: null,
      };
    case 'COMPANY_HAS_NO_EMPLOYEES':
      return {
        ...state,
        staff: 0,
        loadingStaff: true,
      };
    case 'FOUND_COMPANY_EMPLOYEES':
      //console.log('we found employee', action.companyEmployees);
      return {
        ...state,
        staff: [...state.staff, action.companyEmployees],
        loadingStaff: true,
      };
    case 'ERROR_SEARCHING_FOR_EMPLOYEES':
      return {
        ...state,
        staffError: action.err.message,
        staffErrorPopup: true,
        loadingStaff: true,
      };
    case 'STARTING_FRESH_STAFF_SEARCH':
      return {
        ...state,
        staff: [],
      };
    default:
      return state;
  }
};

export default staffReducer;
