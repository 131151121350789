export const sendError = (error) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    console.log('this is my error i am sending', error);
    firestore
      .collection('errors')
      .doc()
      .set({
        site: 'clinic',
        error: error.error.toString(),
        errorInfo: error.errorInfo.componentStack,
        profile: error.profile,
        url: error.url,
        date: new Date(),
        lang: error.lang,
      })
      .then(() => {
        console.log('it worked');
        dispatch({ type: 'ERROR_LOGGED' });
      })
      .catch((err) => {
        console.log('it didnt work', err);
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};
