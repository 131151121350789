export const listCoursesAvailble = (technologies) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //console.log('these are my technologies', technologies)
    let availableCourses = [];
    technologies.map((t) => {
      //console.log('this is my technology id', t)
      return firestore
        .collection('courses')
        .where('technology', '==', t)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              return availableCourses.push({ id: doc.id, data: doc.data() });
            });
          } else {
            //console.log('No course found');
            return;
          }
        })
        .catch((err) => {
          return dispatch({ type: 'NO_COURSES_FOUND', err });
        });
    });

    dispatch({ type: 'COURSES_FOUND', availableCourses });
  };
};

export const findTechnologyCourses = (techID) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //console.log('these are my technologies', techID);

    firestore
      .collection('courses')
      .where('technology', '==', techID)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          const techCourses = [];
          snapshot.forEach((doc) => {
            //console.log('this is the course I found', doc.data());
            techCourses.push({ id: doc.id, data: doc.data() });
          });
          dispatch({ type: 'COURSES_FOUND', techCourses });
        } else {
          //console.log('No course found');
          return;
        }
      })
      .catch((err) => {
        return dispatch({ type: 'NO_COURSES_FOUND', err });
      });
  };
};

export const enrollUserInCourse = ({ course, user }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //first to check to see if the corse is actually in the users list
    const courseField = 'courses.' + [course.id];

    firestore
      .collection('users')
      .doc(user)
      .update({
        [courseField]: {
          name: course.data.title,
          page: 0,
          courseLength: course.data.pages.length,
          quizCompleted: false,
          inPersonTrainingComplete: false,
          startDate: new Date(),
        },
        //Add the page to the course
      })
      .then(() => {
        dispatch({ type: 'COURSE_STARTED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_START_COURSE', err });
      });
  };
};

export const getSlideImage = ({ imageURL, pageID, lang }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();
    //console.log('this is my imageURL', imageURL);
    if (imageURL) {
      storage
        .child(imageURL)
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function (event) {
            //var blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

          let resp = { url: url, pageID: pageID, lang: lang };
          dispatch({ type: 'SLIDE_IMAGE_FOUND', resp });
          // Or inserted into an <img> element:
          //let img = document.getElementById('myimg');
          //img.src = url;
        })
        .catch((error) => {
          dispatch({ type: 'SLIDE_IMAGE_WAS_NOT_FOUND', error });
        });
    } else {
      dispatch({ type: 'NO_SLIDE_IMAGE' });
    }
  };
};

export const getUsersPage = ({ coursePages, pageNumber, lang }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const storage = firebase.storage().ref();

    let myPageNumber = pageNumber >= 0 ? pageNumber : 1;
    //console.log('this is my pagenumber', myPageNumber);
    //console.log('This is my coursePages ', coursePages);
    //console.log('this is my ');
    let myCoursePage = coursePages[myPageNumber >= coursePages.length ? coursePages.length - 1 : myPageNumber];
    //console.log('this is my myCoursePAge', myCoursePage);

    firestore
      .collection('pages')
      .doc(myCoursePage.id)
      .get()
      .then(async (snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_COURSE_PAGE_FOUND' });
        } else {
          let coursePage = snapshot.data();

          //console.log('this is my coursePage. --> ', coursePage);
          let pageImage = coursePage.pageContents[lang][lang + 'PageImage'];
          //console.log('this is my image url', pageImage);
          let audioURL = await firebase.storage().ref(`audio/${coursePage.course}/${lang}/${snapshot.id}.mp3`).getDownloadURL();

          //console.log('thi is my audio url???? -> ', audioURL);

          if (pageImage) {
            storage
              .child(pageImage)
              .getDownloadURL()
              .then((url) => {
                let xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function (event) {};
                xhr.open('GET', url);
                xhr.send();

                let resp = { url: url, coursePage: coursePage, pageNumber: myPageNumber, audioURL: audioURL };

                dispatch({ type: 'COURSE_PAGE_FOUND', resp });
              })
              .catch((error) => {
                dispatch({ type: 'SLIDE_IMAGE_WAS_NOT_FOUND', error });
              });
          } else {
            dispatch({ type: 'NO_SLIDE_IMAGE' });
          }
        }
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_GET_COURSE_PAGE', err });
      });
  };
};

export const nextSlide = ({ userID, courseID, pageNumber, coursePages, lang }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    //console.log('lets change the page');
    const firestore = getFirestore();
    const firebase = getFirebase();
    const storage = firebase.storage().ref();

    //console.log('this is my userId', userID);
    //console.log('this is my courseID', courseID);
    //console.log('this is my pageNumber', pageNumber);
    //console.log('this is my coursePages', coursePages);
    //console.log('this is my lang', lang);
    let myPageNumber = pageNumber >= 0 ? pageNumber : 1;
    // Things we need to do
    // record the page that you are on in the users database
    //// courseHistory
    /////// courseID
    ///////// pageNumer
    ///////// lang
    ///////// date and time completed on
    //Then update the page they are on in the course in the user
    // courses
    //// courseID
    ////// page + 1
    const courseUpdate = 'courses.' + [courseID] + '.page';
    const courseHistory = 'coursesHistory.' + [courseID];
    let myNextCoursePage = coursePages[myPageNumber + 1];

    dispatch({ type: 'LOADING_NEXT_PAGE' });

    firestore
      .collection('users')
      .doc(userID)
      .update({
        [courseHistory]: firestore.FieldValue.arrayUnion({
          lang: lang,
          page: myPageNumber,
          completedDate: new Date(),
          nextStep: 'next',
        }),
        [courseUpdate]: myPageNumber + 1,
      })
      .then((resp) => {
        firestore
          .collection('pages')
          .doc(myNextCoursePage.id)
          .get()
          .then(async (snapshot) => {
            if (snapshot.empty) {
              dispatch({ type: 'NO_COURSE_PAGE_FOUND' });
            } else {
              let coursePage = snapshot.data();

              let audioURL = await firebase.storage().ref(`audio/${coursePage.course}/${lang}/${snapshot.id}.mp3`).getDownloadURL();

              let pageImage = coursePage.pageContents[lang][lang + 'PageImage'];
              //console.log('this is my image url', pageImage);
              if (pageImage) {
                storage
                  .child(pageImage)
                  .getDownloadURL()
                  .then((url) => {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = function (event) {};
                    xhr.open('GET', url);
                    xhr.send();

                    let resp = { url: url, coursePage: coursePage, pageNumber: myPageNumber + 1, audioURL: audioURL };

                    dispatch({ type: 'COURSE_PAGE_FOUND', resp });
                  })
                  .catch((error) => {
                    dispatch({ type: 'SLIDE_IMAGE_WAS_NOT_FOUND', error });
                  });
              } else {
                dispatch({ type: 'NO_SLIDE_IMAGE' });
              }
            }
          })
          .catch((err) => {
            dispatch({ type: 'FAILED_TO_GET_COURSE_PAGE', err });
          });

        //dispatch({ type: 'USER_HAS_MOVED_ONTO_NEXT_PAGE' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_UPDATING_COURSE_HISTORY', err });
      });

    // Progress to the next page
    //We will see if updating this will trigger the next page.
    //If not we might have to do the step up ahead to trigger the page switched
  };
};

export const closeCoursePopUps = () => {
  return (dispatch, getState) => {
    //console.log('are we going to close the course popup');
    dispatch({ type: 'CLOSE_COURSE_POPUPS' });
  };
};

export const prevSlide = ({ userID, courseID, pageNumber, coursePages, lang }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    //console.log('lets change the page');
    const firestore = getFirestore();
    const firebase = getFirebase();
    const storage = firebase.storage().ref();

    //console.log('this is my userId', userID);
    //console.log('this is my courseID', courseID);
    //console.log('this is my pageNumber', pageNumber);
    //console.log('this is my coursePages', coursePages);
    //console.log('this is my lang', lang);

    // Things we need to do
    // record the page that you are on in the users database
    //// courseHistory
    /////// courseID
    ///////// pageNumer
    ///////// lang
    ///////// date and time completed on
    //Then update the page they are on in the course in the user
    // courses
    //// courseID
    ////// page + 1
    if (pageNumber >= 1) {
      const courseUpdate = 'courses.' + [courseID] + '.page';
      const courseHistory = 'coursesHistory.' + [courseID];
      let myNextCoursePage = coursePages[pageNumber - 1];

      dispatch({ type: 'LOADING_NEXT_PAGE' });

      firestore
        .collection('users')
        .doc(userID)
        .update({
          [courseHistory]: firestore.FieldValue.arrayUnion({
            lang: lang,
            page: pageNumber,
            completedDate: new Date(),
            nextStep: 'prev',
          }),
          [courseUpdate]: pageNumber - 1,
        })
        .then((resp) => {
          firestore
            .collection('pages')
            .doc(myNextCoursePage.id)
            .get()
            .then(async (snapshot) => {
              if (snapshot.empty) {
                dispatch({ type: 'NO_COURSE_PAGE_FOUND' });
              } else {
                let coursePage = snapshot.data();
                let audioURL = await firebase.storage().ref(`audio/${coursePage.course}/${lang}/${snapshot.id}.mp3`).getDownloadURL();
                let pageImage = coursePage.pageContents[lang][lang + 'PageImage'];
                //console.log('this is my image url', pageImage);
                if (pageImage) {
                  storage
                    .child(pageImage)
                    .getDownloadURL()
                    .then((url) => {
                      let xhr = new XMLHttpRequest();
                      xhr.responseType = 'blob';
                      xhr.onload = function (event) {};
                      xhr.open('GET', url);
                      xhr.send();

                      let resp = { url: url, coursePage: coursePage, pageNumber: pageNumber - 1, audioURL: audioURL };

                      dispatch({ type: 'COURSE_PAGE_FOUND', resp });
                    })
                    .catch((error) => {
                      dispatch({ type: 'SLIDE_IMAGE_WAS_NOT_FOUND', error });
                    });
                } else {
                  dispatch({ type: 'NO_SLIDE_IMAGE' });
                }
              }
            })
            .catch((err) => {
              dispatch({ type: 'FAILED_TO_GET_COURSE_PAGE', err });
            });
        })
        .catch((err) => {
          dispatch({ type: 'ERROR_UPDATING_COURSE_HISTORY', err });
        });
    }
  };
};

export const completCourse = ({ userID, courseID }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //console.log('lets change the page');
    const firestore = getFirestore();

    const courseUpdate = 'courses.' + [courseID] + '.completed';

    //dispatch({ type: 'LOADING_NEXT_PAGE' });
    //We need to then find the quiz and send it off

    firestore
      .collection('users')
      .doc(userID)
      .update({
        [courseUpdate]: true,
      })
      .then((resp) => {
        dispatch({ type: 'COMPLETED_COURSE' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_UPDATING_COURSE_HISTORY', err });
      });
  };
};

export const userCourseProgress = ({ userInfo, step }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //console.log('lets change the page');
    const progress = { user: userInfo, step: step };
    dispatch({ type: 'SET_USERS_COURSE_STATE', progress });
  };
};
