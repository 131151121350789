export const closeQuizPopups = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'CLOSE_QUIZ_POPUPS' });
  };
};

export const loadQuiz = ({ quizID, user, type }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('looking for thie follwoing quiz', quizID);

    firestore
      .collection('quiz')
      .doc(quizID)
      .get()
      .then((resp) => {
        if (!resp.empty) {
          let quizInfo = { id: resp.id, quiz: resp.data(), user: user, type: type };

          //console.log('this is my resp', quizInfo);
          dispatch({ type: 'FOUND_QUIZ', quizInfo });
        } else {
          dispatch({ type: 'NO_QUIZ_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FINDING_QUIZ', err });
      });
  };
};

export const submitAnswer = ({ quizID, answer, userID, user, questionNumber }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //We are going to need to update the user questionHistory
    /////questionHistory
    ////////quizID
    ///////////run#  They might have to take the test multple times we will want to know this
    //////////////Question# : answer
    let runID = user.quizHistory ? (user.quizHistory[quizID] ? Object.keys(user.quizHistory[quizID]).length - 1 : 0) : 0;
    let quizHistory = 'quizHistory.' + quizID + '.' + runID + '.answers.' + (questionNumber === 0 ? 0 : questionNumber - 1);
    //Then we are going to need to get the grab the next question -- we might need to do this in the reducer
    //First check the user profile
    console.log('this is my run ID', runID);
    console.log('this is my quizHistory', quizHistory);
    firestore
      .collection('users')
      .doc(userID)
      .update({
        [quizHistory]: answer,
      })
      .then((resp) => {
        dispatch({ type: 'QUESTION_ANSWER_SUBMITTED' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SUBMITTING_ANSWER', err });
      });
  };
};

export const prevQuestion = ({ currentQuestion }) => {
  return (dispatch, getState, { getFirestore }) => {
    //const firestore = getFirestore();
    //We just want to go back one question.

    dispatch({ type: 'GO_BACK_A_QUESTION', currentQuestion });
    /*
      firestore
        .collection('users')
        .doc(userID)
        .update({
          [quizHistory]: answer,
        })
        .then((resp) => {
          dispatch({ type: 'QUESTION_ANSWER_SUBMITTED' });
        })
        .catch((err) => {
          dispatch({ type: 'ERROR_SUBMITTING_ANSWER', err });
        });
        */
  };
};

export const finishQuiz = ({ quizID, answer, userID, user, questionNumber, courseID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let runID = user.quizHistory ? (user.quizHistory[quizID] ? Object.keys(user.quizHistory[quizID]).length - 1 : 0) : 0;

    //First grab the quiz
    firestore
      .collection('quiz')
      .doc(quizID)
      .get()
      .then((resp) => {
        if (!resp.empty) {
          const quiz = resp.data().questions;
          //next grab the users results
          firestore
            .collection('users')
            .doc(userID)
            .get()
            .then((resp) => {
              const userResults = resp.data().quizHistory[quizID][runID].answers;
              //Lets add the final quesion to the results
              //console.log('this is my user answers', userResults);
              userResults[questionNumber - 1] = answer;

              //Loop through the results with the answer key
              const quizResults = [];
              for (const [question, answer] of Object.entries(userResults)) {
                //Match map filter through the options till you find a matching my the anwser.
                //return the answer key of the match.
                quizResults.push(quiz[question].options.filter((o) => o.option === answer));
              }

              //We are going to need to update the user questionHistory
              /////questionHistory
              ////////quizID
              ///////////run#  They might have to take the test multple times we will want to know this
              //////////////results: quizResults[Question#: {Question : answer}]
              //////////////runComplete: true
              //////////////runScore: how many complete
              //////////////runPass: true if they get over 75%

              let quizQuestion = 'quizHistory.' + quizID + '.' + runID + '.answers.' + (questionNumber - 1);
              let quizComplete = 'quizHistory.' + quizID + '.' + runID + '.runComplete';
              let quizScore = 'quizHistory.' + quizID + '.' + runID + '.runScore';
              let quizPass = 'quizHistory.' + quizID + '.' + runID + '.runPass';
              let quizCompletedDate = 'quizHistory.' + quizID + '.' + runID + '.completedDate';

              let totalQuestionCount = quizResults.length;
              let userCorrectAnswers = [];

              quizResults.map((q) => {
                //console.log('thsi is my q', q);
                if (q.length > 0) {
                  if (q[0].answer === true) {
                    return userCorrectAnswers.push(1);
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              });
              let userQuizScore = userCorrectAnswers.length / totalQuestionCount;
              //console.log('this is my question count to devide by', totalQuestionCount);
              //console.log('this is how many i got right', userCorrectAnswers);
              let userPass = userQuizScore >= 0.75;

              firestore
                .collection('users')
                .doc(userID)
                .update({
                  [quizQuestion]: answer,
                  [quizComplete]: true,
                  [quizScore]: userCorrectAnswers.length,
                  [quizPass]: userPass,
                  [quizCompletedDate]: new Date(),
                })
                .then((resp) => {
                  let results = { results: quizResults };
                  let courseUpdate = 'courses.' + courseID + '.quizCompleted';
                  let coursePass = 'courses.' + courseID + '.quizPass';
                  let quizIDPass = 'courses.' + courseID + '.quizID';
                  let quizScore = 'courses.' + courseID + '.quizScore';

                  firestore
                    .collection('users')
                    .doc(userID)
                    .update({
                      [courseUpdate]: true,
                      [coursePass]: userPass,
                      [quizIDPass]: quizID,
                      [quizScore]: userCorrectAnswers.length + '/' + totalQuestionCount,
                    });

                  dispatch({ type: 'QUIZ_COMPLETE', results });
                })
                .catch((err) => {
                  dispatch({ type: 'ERROR_UPDATING_USERS_RESULTS', err });
                });

              //Now we need to update the users database and pass the dispatch
              //console.log('this is my quizResults', quizResults);
            })
            .catch((err) => {
              dispatch({ type: 'ERROR_GETTING_USERS_RESULTS', err });
            });
        } else {
          dispatch({ type: 'ERROR_FINDING_QUIZ' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FINISHING_QUIZ', err });
      });

    /*
      let runID = user.quizHistory ? (user.quizHistory[quizID] ? Object.keys(user.quizHistory[quizID]).length - 1 : 0) : 0;
    let quizHistory = 'quizHistory.' + quizID + '.' + runID + '.' + (questionNumber - 1);
    //Then we are going to need to get the grab the next question -- we might need to do this in the reducer
    //First check the user profile

    firestore
      .collection('users')
      .doc(userID)
      .update({
        [quizHistory]: answer,
      })
      .then((resp) => {
        dispatch({ type: 'QUESTION_ANSWER_SUBMITTED' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SUBMITTING_ANSWER', err });
      });

      */
  };
};

export const loadQuizResults = ({ quizID, userAnswers, userPass }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('looking for thie follwoing quiz', quizID);

    firestore
      .collection('quiz')
      .doc(quizID)
      .get()
      .then((resp) => {
        if (!resp.empty) {
          let quizInfo = { id: resp.id, quiz: resp.data(), userAnswers: userAnswers, userPass: userPass };
          //console.log('this is my resp', quizInfo);
          dispatch({ type: 'LOAD_QUIZ_RESULTS', quizInfo });
        } else {
          dispatch({ type: 'NO_QUIZ_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FINDING_QUIZ', err });
      });
  };
};

export const retakeQuiz = ({ quizID, userID, user, courseID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let runID = user.quizHistory ? (user.quizHistory[quizID] ? Object.keys(user.quizHistory[quizID]).length : 0) : 0;

    let courseUpdate = 'courses.' + courseID + '.quizCompleted';
    let quizComplete = 'quizHistory.' + quizID + '.' + runID + '.runComplete';
    // let quizHistory = 'quizHistory. ' + [quizID] + '.' +  Object.keys(user.quizHistory[quizID]).length  ;

    firestore
      .collection('users')
      .doc(userID)
      .update({
        [quizComplete]: false,
        [courseUpdate]: false,
      })
      .then((resp) => {
        dispatch({ type: 'USER_RETAKING_QUIZ' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SUBMITTING_ANSWER', err });
      });
  };
};
