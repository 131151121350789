export const signUpForPromo = ({ clinicID, promoID }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //console.log("this is my clinic id", clinicID);
    //console.log("this is my promoID", promoID);

    firestore
      .collection('promos')
      .doc(promoID)
      .update({
        doctors: firestore.FieldValue.arrayUnion(clinicID),
      })
      .then((resp) => {
        firestore
          .collection('companies')
          .doc(clinicID)
          .update({
            promos: firestore.FieldValue.arrayUnion(promoID),
          })
          .then(() => {
            dispatch({ type: 'DOCTOR_SIGNED_UP_FOR_PROMO' });
          });
      })
      .catch((err) => {
        dispatch({ type: 'DOCTOR_FAILED_TO_SIGN_UP_TO_PROMO', err });
      });
  };
};

export const closePromoMessage = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_PROMO_MESSAGE' });
  };
};

export const nextStep = () => {
  return (dispatch) => {
    dispatch({ type: 'PROMO_NEXT_STEP' });
  };
};

export const backStep = () => {
  return (dispatch) => {
    dispatch({ type: 'PROMO_BACK_STEP' });
  };
};

export const searchForPromoUser = ({ promoID, promoCode }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    //console.log("I have started a search for promoCode", promoCode);
    //console.log("I am searching for the following promo", promoID);

    dispatch({ type: 'STARTING_PROMO_USER_SEARCH' });
    if (promoCode) {
      firestore
        .collection('consumer')
        .where(`promos.${promoID}.promoCode`, '==', promoCode)
        .get()
        .then((snapshot) => {
          //console.log("this is my snapshot ", snapshot);
          if (snapshot.empty) {
            //console.log("no one was found ");
            dispatch({ type: 'NO_USER_FOUND_WITH_PROMO_CODE' });
          } else {
            snapshot.forEach((doc) => {
              //console.log("this is my consumer search", doc.id);
              let resp = { id: doc.id, consumer: doc.data() };
              if (!resp.consumer.promos[promoID].promoCodeClaimed) {
                firebase.analytics().logEvent('promo_code_searched', { promo_code: promoCode, promo: promoID });
                dispatch({ type: 'PROMO_CODE_WAS_FOUND', resp });
              } else {
                firebase.analytics().logEvent('promo_code_search_already_redeemed', { promo_code: promoCode, promo: promoID });
                dispatch({ type: 'PROMO_CODE_HAS_ALREADY_BEEN_REDEEMED' });
              }
            });
          }
        });
    } else {
      dispatch({ type: 'NO_PROMO_CODE_SUBMITTED' });
    }
  };
};

export const submitPromo = (promo) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    //Submit the data into the submittedPromos tabel
    //Data we want to sumbit for this fild

    //Look to make sure that promo code has not been claimed yet
    console.log('this is my promo data that i am sending', promo);

    firestore
      .collection('submittedPromos')
      .where('promoCode', '==', promo.promoCode)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return firestore
            .collection('submittedPromos')
            .add({
              promoID: promo.promoID,
              promoCode: promo.promoCode,
              consumerID: promo.consumerID,
              clinicID: promo.clinicID,
              clinicName: promo.clinicName,
              clinicProvince: promo.clinicProvince,
              //treatmentDate: promo.treatmentDate,
              //clinicAd1: promo.clinicAd1,
              //clinicAd2: promo.clinicAd2,
              //firstTreatment: {
              //  treatmentDate: promo.firstTreatmentDate,
              //  lot: promo.firstTreatmentLot,
              //  ref: promo.firstTreatmentRef,
              //},
              //secondTreatment: {
              //  treatmentDate: promo.secondTreatmentDate,
              // lot: promo.secondTreatmentLot,
              //ref: promo.secondTreatmentRef,
              //},
              submitedDate: firestore.FieldValue.serverTimestamp(),
              promoProcessed: false,
              paided: '',
            })
            .then((resp) => {
              //console.log('this is my resp', resp);
              //Then add the submitted promo reference to the clinics account
              //sumitedPromoID
              //promoID
              //submittedDate

              firestore
                .collection('companies')
                .doc(promo.clinicID)
                .set(
                  {
                    submittedPromos: {
                      [resp.id]: {
                        promoID: promo.promoID,
                        submittedDate: firestore.FieldValue.serverTimestamp(),
                        promoProcessed: false,
                      },
                    },
                  },
                  { merge: true }
                )
                .then((resp) => {
                  //Then switch the consumers promo code to claimed

                  if (promo.consumerID !== '') {
                    console.log('did i get here');
                    firestore
                      .collection('consumer')
                      .doc(promo.consumerID)
                      .set(
                        {
                          promos: {
                            [promo.promoID]: {
                              promoCodeClaimed: true,
                              submittedDate: firestore.FieldValue.serverTimestamp(),
                              clinic: promo.clinicID,
                            },
                          },
                        },
                        { merge: true }
                      )
                      .then((resp) => {
                        firestore
                          .collection('mail')
                          .add({
                            cc: [promo.consumerEmail, promo.clinicEmail],
                            message: {
                              subject: 'Your Promo Code has been Claimed',
                              text: `Hi ${promo.consumerName}, We just wanted to let you know that the following company: ${promo.clinicName} has submitted your promo code.  `,
                              html: `Hi ${promo.consumerName}, We just wanted to let you know that the following company: ${promo.clinicName} has submitted your promo code. `,
                            },
                          })
                          .then(() => {
                            firestore
                              .collection('mail')
                              .add({
                                cc: [promo.repEmail],
                                message: {
                                  subject: 'One of your clinics has submitted a promo',
                                  text: `We just wanted to let you know that the following company: ${promo.clinicName} has submitted a promo code.`,
                                  html: `We just wanted to let you know that the following company: ${promo.clinicName} has submitted a promo code.`,
                                },
                              })
                              .then(() => {
                                firebase.analytics().logEvent('promo_submitted', { promo_code: promo.promoCode, promo: promo.promoID, clinic: promo.clinicID });
                                dispatch({ type: 'PROMO_HAS_BEEN_SUBMITTED' });
                              })
                              .catch((err) => {
                                dispatch({ type: 'ERROR_SENDING_REP_EMAIL', err });
                              });
                          })
                          .catch((err) => {
                            dispatch({ type: 'ERROR_SENDING_CONSUMER_EMAIL', err });
                          });
                      })
                      .catch((err) => {
                        dispatch({ type: 'ERROR_SUBMITTING_PROMO_FOR_CONSUMER', err });
                      });
                    //submittedDate
                    //promoCodeClaimed:true
                  } else {
                    firestore
                      .collection('mail')
                      .add({
                        cc: [promo.repEmail],
                        message: {
                          subject: 'One of your clinics has submitted a promo',
                          text: `We just wanted to let you know that the following company: ${promo.clinicName} has submitted a promo code.`,
                          html: `We just wanted to let you know that the following company: ${promo.clinicName} has submitted a promo code.`,
                        },
                      })
                      .then(() => {
                        firebase.analytics().logEvent('promo_submitted', { promo_code: promo.promoCode, promo: promo.promoID, clinic: promo.clinicID });
                        dispatch({ type: 'PROMO_HAS_BEEN_SUBMITTED' });
                      })
                      .catch((err) => {
                        dispatch({ type: 'ERROR_SENDING_REP_EMAIL', err });
                      });
                  }
                })
                .catch((err) => {
                  dispatch({ type: 'ERROR_SUBMITTING_PROMO_FOR_CLINIC', err });
                });
            })
            .catch((err) => {
              dispatch({ type: 'ERROR_SUBMITTING_PROMO', err });
            });
        } else {
          return dispatch({ type: 'PROMO_CODE_HAS_ALREADY_BEEN_REDEEMED' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SUBMITTING_PROMO', err });
      });
  };
};

export const startPromoSearch = () => {
  return (dispatch) => {
    dispatch({ type: 'PROMO_START_FRESH' });
  };
};

export const sendRepEmailForPromo = ({ promo, company, rep, user }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //Submit the data into the submittedPromos tabel
    //Data we want to sumbit for this fild

    //Look to make sure that promo code has not been claimed yet
    firestore
      .collection('reps')
      .doc(rep)
      .get()
      .then((repSnap) => {
        let repEmail = repSnap.data().email;
        let companyInfo = company[0];
        //console.log('this is my company', companyInfo);
        firestore
          .collection('mail')
          .add({
            cc: [repEmail, user.email],
            message: {
              subject: `${companyInfo.name} is wanting to sign up for ${promo.name}`,
              text: `Hi ${repSnap.data().fullName}, We just wanted to let you know that the following company: ${companyInfo.name} is interested in enrolling in the following promotion ${promo.name}. `,
              html: `Hi ${repSnap.data().fullName}, <br/>We just wanted to let you know that the following company: ${companyInfo.name} is interested in enrolling in the following promotion ${promo.name}. `,
            },
          })
          .then((resp) => {
            dispatch({ type: 'EMAIL_SENT_TO_REP_FOR_PROMO' });
          });
      });
  };
};

export const uploadClinicPromoAds = ({ promoID, clinicID, promoAd1, promoAd2 }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    //Upload ad1 and then ad 2
    // /promos/ads/clinicID/ad1
    // /promos/ads/clinicID/ad2

    //Once uploaded then add a new entry to the clinic profile
    // promoAds/promoID/ad1:url promoAds/promoID/ad2:url

    firebase.uploadFiles(`promos/ads/${clinicID}/`, promoAd1).then((ad1SnapShot) => {
      firebase.uploadFiles(`promos/ads/${clinicID}/`, promoAd2).then((ad2SnapShot) => {
        let ad1Path = 'promoAds.' + promoID + '.ad1';
        let ad2Path = 'promoAds.' + promoID + '.ad2';

        firestore
          .collection('companies')
          .doc(clinicID)
          .update({
            [ad1Path]: ad1SnapShot[0].uploadTaskSnapshot.metadata.fullPath,
            [ad2Path]: ad2SnapShot[0].uploadTaskSnapshot.metadata.fullPath,
          })
          .then((resp) => {
            console.log('we send a dispatch saying things worked');
            dispatch({ type: 'PROMO_ADS_HAVE_BEEN_UPLOADED' });
          });
      });
    });
  };
};
