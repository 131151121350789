import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
//import { Redirect } from 'react-router-dom';
//import * as ROUTES from '../../constants/routes';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typograhy from '@material-ui/core/Typography';

import styles from '../Styles';
import CancelIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { signOut, userAgreedToTerms } from '../../actions/authActions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

const INITIAL_STATE = {};

class TermsServicesPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  agreeToTerms = (event) => {
    event.preventDefault();
    this.props.userAgreedToTerms(this.props.auth.uid);
    //This is the funtion that will update the database
  };

  cancelAgreement = () => {
    // we will log them out.
    this.props.signOut();
  };

  render() {
    const { profile, t, classes, auth } = this.props;

    //if (profile.isLoaded && profile.isEmpty) return <Redirect to={ROUTES.SIGN_OUT} />;

    if (profile.isLoaded && auth.uid && !profile.isEmpty) {
      return (
        <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby='agree-to-terms-and-services' aria-describedby='agree-to-terms-and-services' open={!profile.agreeToTerms} classes={{ paper: classes.termPopUp }}>
          <DialogTitle id='agree-to-terms-and-services-title'>
            <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
              <Grid item xs={10}>
                <Typograhy variant='h6'>{t('terms.pleaseRead')}</Typograhy>
              </Grid>
              <Grid item xs={2} align='right'>
                <IconButton onClick={this.cancelAgreement}>
                  <CancelIcon className={classes.soltaColor} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Typography variant='h5'>{t('terms.title')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionOne')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadTwo')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionTwo')}
                </Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionTwob')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadThree')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionThree')}
                </Typography>

                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionThreeb')}
                </Typography>

                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionThreec')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFour')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionFour')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFive')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionFive')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadSix')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSix')}
                </Typography>

                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSixb')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadSeven')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSeven')}
                </Typography>

                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSevenb')}
                </Typography>

                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSevenc')}
                </Typography>

                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSevend')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadEight')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionEight')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadNine')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionNine')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadTen')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionTen')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadEleven')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionEleven')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadTwelve')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionTwelve')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadThirteen')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionThirteen')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFourteen')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionFourteen')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFifteen')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionFifteen')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadSixteen')}</Typography>
                <Typography variant='body2' gutterBottom>
                  {t('terms.sectionSixteen')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' className={classes.fineprint}>
                  {t('terms.fineprint')}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={4} align='left'>
                <Button onClick={this.cancelAgreement}>{t('buttons.cancel')}</Button>
              </Grid>
              <Grid item xs={8} align='right'>
                <Button color='primary' onClick={this.agreeToTerms}>
                  {t('terms.agree')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      );
    } else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    userAgreedToTerms: (user) => dispatch(userAgreedToTerms(user)),
  };
};

TermsServicesPopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TermsServicesPopup);
