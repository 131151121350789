export const closeMailPopUp = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_MAIL_POPUPS' });
  };
};

export const sendClinicalTrainerEmail = ({ userID, clinicID, user, emailMessage }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('clinical_training_email_sent', { company_id: clinicID, user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    let repEmail = 'Jill.faulds@solta.com';

    firestore
      .collection('mail')
      .add({
        cc: [user.email, repEmail],
        message: {
          subject: `A question from the Solta eLearning Platform`,
          text: 'Confirmation of your question sent to your Solta Clinical Trainer - Your Question was - ' + emailMessage,
          html: '<p>Confirmation of your question sent to your Solta Clinical Trainer</p> <p> Your question was </p> <p>' + emailMessage + '</p>',
        },
      })
      .then(() => {
        dispatch({ type: 'CLINICAL_TRAINING_QUESTION_EMAIL_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_CLINICAL_TRAINING_QUESTION_EMAIL', err });
      });
  };
};
