const initState = {
  mailError: null,
  mailMessage: null,
  mailPopUp: false,
  mailErrorPopup: false,
};

const mailReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_MAIL_POPUPS':
      return {
        ...state,
        mailPopUp: false,
        mailErrorPopup: false,
        mailError: null,
        mailMessage: null,
      };
    case 'CLINICAL_TRAINING_QUESTION_EMAIL_SENT':
      return {
        ...state,
        mailPopUp: true,
        mailMessage: 'Your email has been sent',
      };
    case 'ERROR_SENDING_CLINICAL_TRAINING_QUESTION_EMAIL':
      return {
        ...state,
        mailError: action.err.message,
        mailErrorPopup: true,
      };
    default:
      return state;
  }
};

export default mailReducer;
