import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../images/solta_logo.svg';

const HeaderStyles = makeStyles(theme => ({
    logo: {
        marginTop: 30,
        width: 155,
        '@media (max-width: 1025px)': {
            width: 100,
            marginTop: 26
        }
    },
    h1: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        color: '#5A9BAB',
        textAlign: 'right',
        textTransform: 'uppercase',
        fontSize: 42,
        '@media (max-width: 1025px)': {
            fontSize: 28
        }
    },
    blueRow: {
        width: '100%',
        height: 40,
        backgroundColor: '#5A9BAB',
    },
    logoHeader: {
        backgroundColor: '#fff',
        marginBottom: 30
    },
    textRight: {
        paddingTop: 30,
        textAlign: 'right'
    }
}));



const LogoHeader = ({ page }) => {
    const classes = HeaderStyles();


    return (
        <div className={classes.logoHeader}>
            <div className="container">
                <div className="row logo-strip">
                    <div className="col s4 solta-logo">
                        <img src={Logo} alt="Solta" className={classes.logo} />
                    </div>
                    <div className="col s8">
                        <h4 className={classes.textRight}>{page}</h4>
                    </div>
                </div>
            </div>
        </div>
    );

};


export default LogoHeader;