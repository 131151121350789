import React, { Component } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { closeAuthPopUp } from '../../actions/authActions';
import { closeCompanyPopUp } from '../../actions/companyActions';
import { closeCoursePopUps } from '../../actions/coursesActions';
import { closeMailPopUp } from '../../actions/mailActions';
import { closePromoMessage } from '../../actions/promoActions';
import { closeQuizPopups } from '../../actions/quizActions';
import { closeStaffPopUps } from '../../actions/staffActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
//import { closeTierPopUps } from '../../actions/tierActions';
//import { closeItemPopUps } from '../../actions/itemActions';
import styles from '../Styles';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

function Alert(props) {
  return <MuiAlert elevation={10} variant='filled' {...props} />;
}

class SystemAlerts extends Component {
  closePopUps = () => {
    this.props.closeStaffPopUps();
    this.props.closeAuthPopUp();
    this.props.closeCoursePopUps();
    //this.props.closeTierPopUps();
    //this.props.closeItemPopUps();
    this.props.closeCompanyPopUp();
    this.props.closeMailPopUp();
    this.props.closeQuizPopups();
    this.props.closePromoMessage();
  };

  render() {
    const {
      authError,
      authMessage,
      authPopUp,
      authErrorPopUp,
      staffError,
      staffErrorPopup,
      staffMessage,
      staffPopUp,
      coursesError,
      courseErrorPopup,
      coursesMessage,
      courseMessagePopup,
      tierError,
      tierErrorPopup,
      tierMessage,
      tierPopUp,
      itemError,
      itemMessage,
      itemPopUp,
      itemErrorPopup,
      companyError,
      companyMessage,
      companyErrorPopup,
      companyPopUp,
      mailError,
      mailErrorPopup,
      mailMessage,
      mailPopUp,
      quizError,
      quizMessage,
      quizMessagePopup,
      quizErrorPopup,
      promoErrorPopUp,
      promoError,
      promoMessage,
      promoPopUp,
    } = this.props;

    return (
      <div className='alerts'>
        <Snackbar open={staffPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Staff Message - {staffMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={staffErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Staff Error - {staffError}
          </Alert>
        </Snackbar>
        <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Auth Message - {authMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Auth Error - {authError}
          </Alert>
        </Snackbar>

        <Snackbar open={courseMessagePopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Course Message - {coursesMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={courseErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Course Error - {coursesError}
          </Alert>
        </Snackbar>

        <Snackbar open={tierPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Tier Message - {tierMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={tierErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Tier Error - {tierError}
          </Alert>
        </Snackbar>

        <Snackbar open={itemPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Item Message - {itemMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={itemErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Item Error - {itemError}
          </Alert>
        </Snackbar>

        <Snackbar open={companyPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Company Message - {companyMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={companyErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Company Error - {companyError}
          </Alert>
        </Snackbar>

        <Snackbar open={mailPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Mail Message - {mailMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={mailErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Mail Error - {mailError}
          </Alert>
        </Snackbar>

        <Snackbar open={quizMessagePopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Quiz Message - {quizMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={quizErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Quiz Error - {quizError}
          </Alert>
        </Snackbar>

        <Snackbar open={promoPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Promo Message - {promoMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={promoErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Promo Error - {promoError}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is the alerts state', state);
  return {
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authPopUp: state.auth.authPopUp,
    authErrorPopUp: state.auth.authErrorPopUp,
    staffError: state.staff.staffError,
    staffMessage: state.staff.staffMessage,
    staffPopUp: state.staff.staffPopUp,
    staffErrorPopup: state.staff.staffErrorPopup,
    coursesError: state.courses.coursesError,
    coursesMessage: state.courses.coursesMessage,
    courseMessagePopup: state.courses.courseMessagePopup,
    courseErrorPopup: state.courses.courseErrorPopup,
    //tierError: state.tiers.tierError,
    //tierMessage: state.tiers.tierMessage,
    //tierPopUp: state.tiers.tierPopUp,
    //tierErrorPopup: state.tiers.tierErrorPopup,
    //itemError: state.items.itemError,
    //itemMessage: state.items.itemMessage,
    //itemPopUp: state.items.itemPopUp,
    //itemErrorPopup: state.items.itemErrorPopup,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    companyErrorPopup: state.company.companyErrorPopup,
    companyPopUp: state.company.companyPopUp,
    mailError: state.mail.mailError,
    mailMessage: state.mail.mailMessage,
    mailErrorPopup: state.mail.mailErrorPopup,
    mailPopUp: state.mail.mailPopUp,
    quizErrorPopup: state.quiz.quizErrorPopup,
    quizMessagePopup: state.quiz.quizMessagePopup,
    quizError: state.quiz.quizError,
    quizMessage: state.quiz.quizMessage,
    promoError: state.promos.promoError,
    promoErrorPopUp: state.promos.promoErrorPopUp,
    promoMessage: state.promos.promoMessage,
    promoPopUp: state.promos.promoPopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeStaffPopUps: () => dispatch(closeStaffPopUps()),
    closeAuthPopUp: () => dispatch(closeAuthPopUp()),
    closeCoursePopUps: () => dispatch(closeCoursePopUps()),
    //closeTierPopUps: () => dispatch(closeTierPopUps()),
    //closeItemPopUps: () => dispatch(closeItemPopUps()),
    closeCompanyPopUp: () => dispatch(closeCompanyPopUp()),
    closeMailPopUp: () => dispatch(closeMailPopUp()),
    closeQuizPopups: () => dispatch(closeQuizPopups()),
    closePromoMessage: () => dispatch(closePromoMessage()),
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps), withTranslation())(SystemAlerts);
