const initState = {
  quizError: null,
  quizMessage: null,
  quizMessagePopup: false,
  quizErrorPopup: false,
  quiz: null,
  quizLoaded: false,
  quizNumberofQuestions: null,
  quizTitle: null,
  quizQuestion: null,
  question: null,
  questionOptions: null,
  quizID: null,
  resultsLoaded: false,
  quizResults: null,
  quizFullQustions: null,
  quizUserPass: null,
};

const quizReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_QUIZ_POPUPS':
      return {
        ...state,
        quizErrorPopup: false,
        quizMessagePopup: false,
        quizError: null,
        quizMessage: null,
      };

    case 'FOUND_QUIZ':
      let quizRun = action.quizInfo.user.quizHistory ? (action.quizInfo.user.quizHistory[action.quizInfo.id] ? Object.keys(action.quizInfo.user.quizHistory[action.quizInfo.id]).length : 0) : 0;
      let quizQuestion = null;

      if (action.quizInfo.type === 'load') {
        //console.log('this is my quiz History', action.quizInfo.user.quizHistory[action.quizInfo.id]);
        //console.log('this is my quiz run ', quizRun - 1);
        const quizQuestionCount = [];

        if (action.quizInfo.user.quizHistory) {
          if (action.quizInfo.user.quizHistory[action.quizInfo.id]) {
            if (action.quizInfo.user.quizHistory[action.quizInfo.id][quizRun - 1].answers) {
              Object.keys(action.quizInfo.user.quizHistory[action.quizInfo.id][quizRun - 1].answers).map((a) => {
                //console.log('this is my a', a);
                if (a >= 0) {
                  return quizQuestionCount.push(a);
                }
                return true;
              });
            }
          }
        }

        quizQuestion = quizQuestionCount.length > 0 ? quizQuestionCount.length : 0;
      } else {
        quizQuestion = 0;
      }

      //console.log('this is my quizQuestion', quizQuestion);

      let question = action.quizInfo.quiz.questions[quizQuestion].question;

      //console.log('this is my question', question);
      //console.log('this is my question', action.quizInfo.quiz.questions[quizQuestion].question);
      //I want the options but not answer
      let options = action.quizInfo.quiz.questions[quizQuestion].options.map((o) => {
        return o.option;
      });

      //console.log('options', options);
      //I need to load in the question and the options with out the answers
      return {
        ...state,
        quiz: action.quizInfo.quiz,
        quizID: action.quizInfo.id,
        quizNumberofQuestions: action.quizInfo.quiz.questions.length,
        quizTitle: action.quizInfo.quiz.title,
        quizQuestion: quizQuestion + 1,
        question: question,
        questionOptions: options,
        quizLoaded: true,
      };
    case 'NO_QUIZ_FOUND':
      return {
        ...state,
        quizError: 'Sorry no quiz was found',
        quizErrorPopup: true,
      };
    case 'ERROR_FINDING_QUIZ':
      return {
        ...state,
        quizError: action.err.message,
        quizErrorPopup: true,
      };
    case 'QUESTION_ANSWER_SUBMITTED':
      //console.log('this is my quizQuestion', state.quizQuestion);
      let newQuestion = state.quiz.questions[state.quizQuestion].question;

      let newOptions = state.quiz.questions[state.quizQuestion].options.map((o) => {
        return o.option;
      });

      let nextQuestion = state.quizQuestion + 1;
      return {
        ...state,
        quizQuestion: nextQuestion,
        question: newQuestion,
        questionOptions: [...newOptions],
      };
    case 'ERROR_SUBMITTING_ANSWER':
      return {
        ...state,
        quizError: action.err.message,
        quizErrorPopup: true,
      };
    case 'GO_BACK_A_QUESTION':
      //console.log('this is my current quizQustion #', action.currentQuestion);

      let prevQuestion = state.quiz.questions[action.currentQuestion - 2].question;

      let prevOptions = state.quiz.questions[action.currentQuestion - 2].options.map((o) => {
        return o.option;
      });

      return {
        ...state,
        quizQuestion: state.quizQuestion - 1,
        question: prevQuestion,
        questionOptions: prevOptions,
      };

    case 'LOAD_QUIZ_RESULTS':
      //console.log('this is my quiz info', action.quizInfo);
      let userScore = 0;
      let totalQuestions = 0;
      action.quizInfo.quiz.questions.map((q, i) => {
        //console.log('this is my q', q);
        totalQuestions++;
        return q.options.map((o, n) => {
          //console.log('this is my option', o);
          //console.log('this is my userpick', action.quizInfo.userAnswers[i]);
          if (o.option === action.quizInfo.userAnswers[i]) {
            action.quizInfo.quiz.questions[i].options[n].userPicked = true;
            if (o.answer === true) {
              userScore++;
              return (action.quizInfo.quiz.questions[i].userGotAnswerCorrect = true);
            } else {
              return (action.quizInfo.quiz.questions[i].userGotAnswerCorrect = false);
            }
          } else {
            return (action.quizInfo.quiz.questions[i].options[n].userPicked = false);
          }
        });
      });

      //console.log('this is my userScore', userScore);
      //console.log('this is my totalQuestions', totalQuestions);
      let userTotalQuizScore = userScore / totalQuestions;
      let didUserPass = userTotalQuizScore >= 0.75;

      //console.log(didUserPass);

      return {
        ...state,
        resultsLoaded: true,
        quizUserPass: didUserPass,
        quizResults: action.quizInfo.userAnswers,
        quizTitle: action.quizInfo.quiz.title,
        quizFullQustions: action.quizInfo.quiz,
        quizID: action.quizInfo.id,
      };

    case 'USER_RETAKING_QUIZ':
      return {
        ...state,
        //resultsLoaded: false,
        //quizLoaded: false,
        quizQuestion: 1,
      };

    default:
      return state;
  }
};

export default quizReducer;
