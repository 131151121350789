const initState = {
  course: {
    id: '',
    name: '',
    pageNumber: '',
  },
  availableCourses: [],
  availableCoursesLoaded: false,
  activeStep: 0,
  coursesError: null,
  coursesMessage: null,
  courseErrorPopup: false,
  courseMessagePopup: false,
  slideImages: [],
  loadedSlideImage: true,
  coursePage: null,
  coursePageNumber: null,
  userCourseState: null,
  oldSlideImage: null,
  audioURL: '',
};

const coursesReducer = (state = initState, action) => {
  switch (action.type) {
    case 'COURSE_CREATED':
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Course has been started',
        activeStep: 1,
        course: {
          id: action.resp.ref.id,
          name: action.resp.courseDetails.name,
          pageNumber: 1,
        },
      };
    case 'COURSE_ALL_READY_MADE':
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Course has already been made, Please add Pages',
        activeStep: 1,
        course: {
          id: action.resp.ref.docs[0].id,
          name: action.resp.courseDetails.name,
        },
      };
    case 'EDIT_COURSE':
      console.log('this edit deailts', action.courseDetails.editCourse[0]);

      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Lets edit the course',
        activeStep: 1,
        course: {
          id: action.courseDetails.state.existingCourse,
          name: action.courseDetails.editCourse[0].title,
          pageNumber: action.courseDetails.editCourse[0].pages ? action.courseDetails.editCourse[0].pages.length + 1 : 1,
        },
      };
    case 'PAGE_IMAGE_UPLOADED':
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'The pages image has been uploaded',
      };
    case 'PAGE_IMAGE_FAILED_TO_UPLOAD':
      return {
        ...state,
        coursesError: action.err.message,
      };
    case 'FAILED_TO_CREATE_COURSE':
      return {
        ...state,
        coursesError: action.err.message,
      };
    case 'NO_PAGE_TYPE_FOUND':
      return {
        ...state,
        coursesError: 'The page type not found',
      };
    case 'PAGE_HAS_BEEN_CREATED':
      //   /console.log('this is my page number when creating anothe page', state.course.pageNumber);
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Your page has been created',
        course: {
          name: state.course.name,
          id: state.course.id,
          pageNumber: state.course.pageNumber + 1,
        },
      };
    case 'FAILED_TO_ADD_PAGE_TO_DATABASE':
      return {
        ...state,
        coursesError: action.err.message,
      };
    case 'COURSES_FOUND':
      //map through the coureses
      let techCourse = action.techCourses.map((course) => {
        let courseAlreadyLoaded = state.availableCourses.filter((c) => c.id === course.id);

        if (courseAlreadyLoaded.length === 0) {
          return course;
        } else {
          return null;
        }
      });

      if (techCourse[0]) {
        return {
          ...state,
          availableCourses: [...state.availableCourses, ...techCourse],
          availableCoursesLoaded: true,
        };
      } else {
        return {
          ...state,
        };
      }

    case 'SLIDE_IMAGE_FOUND':
      //console.log('this is my action ', action)
      return {
        ...state,
        slideImages: [...state.slideImages, action.resp],
        loadedSlideImage: true,
      };
    case 'SLIDE_IMAGE_WAS_NOT_FOUND':
      //console.log('this is my action ', action)
      return {
        ...state,
        coursesError: action.error,
        courseErrorPopup: true,
        loadedSlideImage: true,
      };
    case 'NO_COURSE_PAGE_FOUND':
      //console.log('this is my action ', action)
      return {
        ...state,
        coursesError: 'No page was found',
        courseErrorPopup: true,
        loadedSlideImage: true,
      };
    case 'FAILED_TO_GET_COURSE_PAGE':
      //console.log('this is my action ', action)
      return {
        ...state,
        coursesError: action.error,
        courseErrorPopup: true,
        loadedSlideImage: true,
      };
    case 'COURSE_PAGE_FOUND':
      //console.log('this is my action ', action);
      return {
        ...state,
        coursePage: action.resp.coursePage,
        slideImages: action.resp.url,
        coursePageNumber: action.resp.pageNumber + 1,
        loadedSlideImage: false,
        play: true,
        audioURL: action.resp.audioURL,
      };

    case 'COMPLETED_COURSE':
      //console.log('this is my action ', action);
      return {
        ...state,
        coursePage: null,
        slideImages: null,
        coursePageNumber: null,
        loadedSlideImage: false,
      };
    case 'LOADING_NEXT_PAGE':
      return {
        ...state,
        loadedSlideImage: true,
        oldSlideImage: state.slideImages,
      };
    case 'CLOSE_COURSE_POPUPS':
      return {
        ...state,
        courseErrorPopup: false,
        courseMessagePopup: false,
        coursesError: null,
        coursesMessage: null,
      };
    case 'SET_USERS_COURSE_STATE':
      //console.log('this is my action', action.progress.step);
      let user = action.progress.user;
      let myProgress = null;

      //If we are loading then we need to check the user last left off
      if (action.progress.step === 'loading') {
        //console.log('lets look through the users', user);
        if (!user.completed) {
          //console.log('we are completed the course');
          myProgress = 'course';
        } else if (user.completed && !user.quizCompleted) {
          //console.log('loading my quiz again');
          myProgress = 'quiz';
        } else {
          //console.log('we are loading results');
          myProgress = 'results';
        }
      } else {
        myProgress = action.progress.step;
      }

      return {
        ...state,
        userCourseState: myProgress,
      };
    default:
      return state;
  }
};

export default coursesReducer;
