export const LANDING = '/';
export const LOGIN = '/login';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const SIGN_UP = '/signup';
export const SIGN_OUT = '/signout';
export const PASSWORD_FORGET = '/pw-forget';
export const DOCTOR_UPLOAD = '/upload-doctor';
export const PURE_UPLOAD = '/upload-tiers';
export const ITEM_UPLOAD = '/upload-items';
export const SALES_REP_UPLOAD = '/upload-sales-rep';
export const CALENDAR = '/calendar';
export const SEND = '/send';
export const SALES_SHEETS = '/sales-sheets';
export const USERS = '/users/:id';
export const USER = '/users/';
export const COMPANY = '/company/:id';
export const EDIT_COMPANY = '/company/edit/:id';
export const COMPANY_FORM = '/company-signup';
export const COURSES = '/courses/';
export const COURSE = '/courses/:id';
export const REVIEW_COURSE = '/course/r/:courseid';
export const REVIEW_QUIZID = '/course/q/:courseid';
export const REVIEW_QUIZ = '/course/q/';
export const COURSE_VIEW = '/course/:courseid';
export const EDIT_COURSE = '/courses/edit/:id';
export const EDIT_COURSE_PAGE = '/courses/edit/page/:id';
export const REGISTER_USER_TO_CLINIC = '/signup/:id';
export const FORGOTTEN_PASSWORD = '/password-reset';
export const STAFF = '/staff';
export const TERMS = '/terms';
export const REG = '/register';
export const CERTS = '/certs';
export const RESOURCE_LIBRARY = '/resource-library';
export const CLINICAL_LIBRARY = '/clinical-library';
export const PROMO_SIGNUP = '/promo-signup';
