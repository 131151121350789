const initState = {
  technologyError: null,
  technologyMessage: null,
  companyTechnology: [],
  techBrandLogo: null,
};

const technologyReducer = (state = initState, action) => {
  switch (action.type) {
    case 'TECHNOLOGY_ADDED':
      return {
        ...state,
        technologyError: null,
        technologyMessage: 'Technology has been added',
      };
    case 'TECHNOLOGY_IMAGE_WAS_UPLOADED':
      return {
        ...state,
        technologyError: null,
        technologyMessage: 'Technology image has been uploaded',
      };
    case 'TECHNOLOGY_ALREADY_ADDED':
      return {
        ...state,
        technologyError: null,
        technologyMessage: 'Technology is already in the system',
      };
    case 'TECHNOLOGY_WAS_NOT_ADDED_TO_DATABASE':
      return {
        ...state,
        technologyError: action.err.message,
        technologyMessage: 'Technology Failed to be hadded to Database',
      };

    case 'TECHNOLOGY_IMAGE_FAILED_TO_UPLOAD':
      return {
        ...state,
        technologyError: action.err.message,
        technologyMessage: 'Technology Image Failed to be uploaded to the server',
      };
    case 'ERROR_LOOKING_UP_TECH':
      return {
        ...state,
        technologyError: action.err.message,
        technologyMessage: 'There was an error looking up the technology for the company',
      };
    case 'NO_TECHNOLOGIES_FOUND':
      return {
        ...state,
        //technologyError: action.err.message,
        //technologyMessage: 'There was an error looking up the technology for the company',
      };
    case 'ADD_COMPANY_TECHNOLOGY':
      return {
        ...state,
        companyTechnology: [...state.companyTechnology, action.tech],
        //technologyError: action.err.message,
        //technologyMessage: 'There was an error looking up the technology for the company',
      };
    case 'TECH_BRAND_LOGO_FOUND':
      return {
        ...state,
        techBrandLogo: action.resp,
        //technologyError: action.err.message,
        //technologyMessage: 'There was an error looking up the technology for the company',
      };
    default:
      return state;
  }
};

export default technologyReducer;
