import Geocode from "react-geocode";
import geohash from "ngeohash";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);

export const updateCompany = (company) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log("updateCompany");

    const address =
      company.streetAddress +
      " " +
      company.streetAddress2 +
      " " +
      company.city +
      " " +
      company.province +
      " " +
      company.postalCode +
      " " +
      company.country;
    //console.log('this is my new company', company);
    Geocode.fromAddress(address).then((response) => {
      const cityLat = response.results[0].geometry.location.lat;
      const cityLong = response.results[0].geometry.location.lng;
      const cityGeohash = geohash.encode(cityLat, cityLong);

      //Check to see if they updated the logo
      if (company.logo[0].size) {
        const safeCompanyName = company.name.replace(/\s+/g, "-").toLowerCase();
        //console.log('I have a file', company.logo[0]);
        firebase
          .uploadFiles(`companies/${safeCompanyName}/logos`, company.logo)
          .then((snapshot) => {
            //Then update the company
            firestore
              .collection("companies")
              .doc(company.id)
              .update({
                name: company.name,
                location: {
                  streetAddress: company.streetAddress,
                  streetAddress2: company.streetAddress2,
                  city: company.city,
                  province: company.province,
                  postalCode: company.postalCode,
                  country: company.country,
                  lat: cityLat,
                  long: cityLong,
                  geohash: cityGeohash,
                },
                languages: {
                  allLanguages: company.selectedLanguages,
                  preferedLanguage: company.preferedLanguage,
                },
                website: company.website,
                socialMediaAccounts: {
                  facebook: company.facebook,
                  instagram: company.instagram,
                  twitter: company.twitter,
                },
                phoneNumber: company.phoneNumber,
                phoneNumber2: company.phoneNumber2,
                email: company.email,
                companyLevel: company.companyLevel,
                lastUpdated: new Date(),
                logo: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
                owner: company.owner,
                staff: {},
                technologies: company.selectedTechnologiesIDs,
                companyHasBeenReviewed: true,
              })
              .then(() => {
                firebase
                  .analytics()
                  .logEvent("business_updated", { company: company.id });

                dispatch({ type: "COMPANY_HAS_BEEN_UPDATED" });
              })
              .catch((err) => {
                dispatch({ type: "ERROR_UPDATING_COMPANY", err });
              });
          })
          .then(() => {
            dispatch({ type: "COMPANY_LOGO_UPDATED" });
          })
          .catch((err) => {
            dispatch({ type: "COMPANY_LOGO_FAILED_TO_UPLOAD", err });
          });
      } else {
        //console.log('I dont have a file', company.logo[0].name);
        //Then update the company
        firestore
          .collection("companies")
          .doc(company.id)
          .update({
            name: company.name,
            location: {
              streetAddress: company.streetAddress,
              streetAddress2: company.streetAddress2,
              city: company.city,
              province: company.province,
              postalCode: company.postalCode,
              country: company.country,
              lat: cityLat,
              long: cityLong,
              geohash: cityGeohash,
            },
            languages: {
              allLanguages: company.selectedLanguages,
              preferedLanguage: company.preferedLanguage,
            },
            website: company.website,
            socialMediaAccounts: {
              facebook: company.facebook,
              instagram: company.instagram,
              twitter: company.twitter,
            },
            phoneNumber: company.phoneNumber,
            phoneNumber2: company.phoneNumber2,
            email: company.email,
            companyLevel: company.companyLevel,
            lastUpdated: new Date(),
            logo: company.logo[0].name,
            owner: company.owner,
            staff: {},
            technologies: company.selectedTechnologiesIDs,
            companyHasBeenReviewed: true,
          })
          .then(() => {
            firebase
              .analytics()
              .logEvent("business_updated", { company: company.id });
            dispatch({ type: "COMPANY_HAS_BEEN_UPDATED" });
          })
          .catch((err) => {
            dispatch({ type: "ERROR_UPDATING_COMPANY", err });
          });
      }
    });
  };
};

export const updateReviewCompany = (company) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my new company', company);
    console.log("updateReviewCompany");

    const address =
      company.streetAddress +
      " " +
      company.streetAddress2 +
      " " +
      company.city +
      " " +
      company.province +
      " " +
      company.postalCode +
      " " +
      company.country;

    Geocode.fromAddress(address).then((response) => {
      const cityLat = response.results[0].geometry.location.lat;
      const cityLong = response.results[0].geometry.location.lng;
      const cityGeohash = geohash.encode(cityLat, cityLong);
      //Check to see if they updated the logo

      //console.log('this is my company logo', company.logo);

      if (company.logo[0].size) {
        //console.log('I have a file', company.logo[0]);
        const safeCompanyName = company.name.replace(/\s+/g, "-").toLowerCase();

        console.log("this is my company name", company);
        console.log("this is my safeCompany Name", safeCompanyName);
        firebase
          .uploadFiles(`companies/${safeCompanyName}/logos`, company.logo)
          .then((snapshot) => {
            //Then update the company
            firestore
              .collection("companies")
              .doc(company.id)
              .update({
                name: company.name,
                location: {
                  streetAddress: company.streetAddress,
                  streetAddress2: company.streetAddress2,
                  city: company.city,
                  province: company.province,
                  postalCode: company.postalCode,
                  country: company.country,
                  lat: cityLat,
                  long: cityLong,
                  geohash: cityGeohash,
                },
                languages: {
                  allLanguages: company.selectedLanguages,
                  preferedLanguage: company.preferedLanguage,
                },
                website: company.website,
                socialMediaAccounts: {
                  facebook: company.facebook,
                  instagram: company.instagram,
                  twitter: company.twitter,
                },
                phoneNumber: company.phoneNumber,
                phoneNumber2: company.phoneNumber2,
                email: company.email,
                companyLevel: company.companyLevel,
                lastUpdated: new Date(),
                logo: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
                owner: company.owner,
                staff: {},
                technologies: company.selectedTechnologiesIDs,
                companyHasBeenReviewed: true,
              })
              .then(() => {
                firebase
                  .analytics()
                  .logEvent("business_reviewed", { company: company.id });

                dispatch({ type: "COMPANY_HAS_BEEN_REVIEWED" });
              })
              .catch((err) => {
                dispatch({ type: "ERROR_UPDATING_COMPANY", err });
              });
          })
          .then(() => {
            dispatch({ type: "COMPANY_LOGO_UPDATED" });
          })
          .catch((err) => {
            dispatch({ type: "COMPANY_LOGO_FAILED_TO_UPLOAD", err });
          });
      } else {
        //console.log('I dont have a file', company.logo[0].name)
        //Then update the company
        firestore
          .collection("companies")
          .doc(company.id)
          .update({
            name: company.name,
            location: {
              streetAddress: company.streetAddress,
              streetAddress2: company.streetAddress2,
              city: company.city,
              province: company.province,
              postalCode: company.postalCode,
              country: company.country,
              lat: cityLat,
              long: cityLong,
              geohash: cityGeohash,
            },
            languages: {
              allLanguages: company.selectedLanguages,
              preferedLanguage: company.preferedLanguage,
            },
            website: company.website,
            socialMediaAccounts: {
              facebook: company.facebook,
              instagram: company.instagram,
              twitter: company.twitter,
            },
            phoneNumber: company.phoneNumber,
            phoneNumber2: company.phoneNumber2,
            email: company.email,
            companyLevel: company.companyLevel,
            lastUpdated: new Date(),
            owner: company.owner,
            staff: {},
            technologies: company.selectedTechnologiesIDs,
            companyHasBeenReviewed: true,
          })
          .then(() => {
            firebase
              .analytics()
              .logEvent("business_reviewed", { company: company.id });
            console.log("company updated - no logo");
            dispatch({ type: "COMPANY_HAS_BEEN_REVIEWED" });
          })
          .catch((err) => {
            dispatch({ type: "ERROR_UPDATING_COMPANY", err });
          });
      }
    });
  };
};

export const getCompanyLogo = (url) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();
    if (url) {
      storage
        .child(url)
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          let xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function (event) {
            //var blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();

          let resp = { url: url };
          dispatch({ type: "LOGO_WAS_FOUND", resp });
          // Or inserted into an <img> element:
          //let img = document.getElementById('myimg');
          //img.src = url;
        })
        .catch((error) => {
          dispatch({ type: "LOGO_WAS_NOT_FOUND", error });
        });
    } else {
      dispatch({ type: "NO_COMPANY_LOGO" });
    }
  };
};

export const getMultipleCompanyLogos = (companies) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();
    if (companies) {
      companies.map((c) => {
        if (c.logo) {
          return storage
            .child(c.logo)
            .getDownloadURL()
            .then((url) => {
              // `url` is the download URL for 'images/stars.jpg'

              // This can be downloaded directly:
              let xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.onload = function (event) {
                //var blob = xhr.response;
              };
              xhr.open("GET", url);
              xhr.send();

              let resp = { url: url, clinicID: c.id };
              dispatch({ type: "MULT_LOGO_WAS_FOUND", resp });
              // Or inserted into an <img> element:
              //let img = document.getElementById('myimg');
              //img.src = url;
            })
            .catch((error) => {
              dispatch({ type: "LOGO_WAS_NOT_FOUND", error });
            });
        } else {
          return dispatch({ type: "NO_COMPANY_LOGO" });
        }
      });
    } else {
      dispatch({ type: "NO_COMPANY_LOGO" });
    }
  };
};

export const closeCompanyPopUp = () => {
  return (dispatch) => {
    dispatch({ type: "CLOSE_COMPANY_POPUP" });
  };
};

export const getCompanyInfo = (companyID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my new company', newCompany);
    //First check to see if company already doesn't exist

    firestore
      .collection("companies")
      .doc(companyID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: "NO_COMPANY_WAS_FOUND" });
        } else {
          let clinic = { id: snapshot.id, data: snapshot.data() };
          dispatch({ type: "COMPANY_FOUND", clinic });
        }
      })
      .catch((err) => {
        dispatch({ type: "ERROR_SEARCHING_FOR_COMPANY", err });
      });
  };
};

export const getCompanyOwner = (userID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    userID.map((u) => {
      return firestore
        .collection("users")
        .doc(u)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            dispatch({ type: "NO_USER_FOUND" });
          } else {
            let user = { id: snapshot.id, data: snapshot.data() };
            dispatch({ type: "COMPANY_OWNER_FOUND", user });
          }
        })
        .catch((err) => {
          dispatch({ type: "ERROR_LOOKING_UP_COMPANY_OWNER", err });
        });
    });
  };
};

export const getCompanyName = (companyID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection("companies")
      .doc(companyID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: "NO_COMPANY_WAS_FOUND" });
        } else {
          let clinic = { id: snapshot.id, data: snapshot.data().name };
          dispatch({ type: "COMPANY_NAME_FOUND", clinic });
        }
      })
      .catch((err) => {
        dispatch({ type: "ERROR_SEARCHING_FOR_COMPANY", err });
      });
  };
};

export const getSingleCompanyInfo = (companyID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my company ID', companyID);
    //First check to see if company already doesn't exist
    firestore
      .collection("companies")
      .doc(companyID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: "NO_COMPANY_WAS_FOUND" });
        } else {
          let clinic = { id: snapshot.id, data: snapshot.data() };
          dispatch({ type: "SINGLE_COMPANY_FOUND", clinic });
        }
      })
      .catch((err) => {
        dispatch({ type: "ERROR_SEARCHING_FOR_COMPANY", err });
      });
  };
};

export const uploadAttendanceCert = ({ clinicID, cert, user, certDate }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    firebase
      .uploadFiles(`certs/${clinicID}`, cert)
      .then((snapshot) => {
        //Then update the company
        let certPath = "certs.attendanceCert";
        firestore
          .collection("companies")
          .doc(clinicID)
          .update({
            [certPath]: firestore.FieldValue.arrayUnion({
              fileName: cert[0].name,
              fileURL: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
              userUploaded: user.fullName,
              userUploadedID: user.id,
              uploadDate: new Date(),
              dateOfCompletion: certDate,
            }),
          })
          .then(() => {
            dispatch({ type: "COMPANY_CERT_HAS_BEEN_ADDED" });
          })
          .catch((err) => {
            dispatch({ type: "ERROR_UPDATING_COMPANY", err });
          });
      })
      .catch((err) => {
        dispatch({ type: "ERROR_UPLOADING_COMPANY_CERT", err });
      });
  };
};

export const uploadInstallCert = ({ clinicID, cert, user, certDate }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    firebase
      .uploadFiles(`certs/${clinicID}`, cert)
      .then((snapshot) => {
        //Then update the company
        let certPath = "certs.installCert";
        firestore
          .collection("companies")
          .doc(clinicID)
          .update({
            [certPath]: [
              {
                fileName: cert[0].name,
                fileURL: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
                userUploaded: user.fullName,
                userUploadedID: user.id,
                uploadDate: new Date(),
                dateOfCompletion: certDate,
              },
            ],
          })
          .then(() => {
            dispatch({ type: "COMPANY_CERT_HAS_BEEN_ADDED" });
          })
          .catch((err) => {
            dispatch({ type: "ERROR_UPDATING_COMPANY", err });
          });
      })
      .catch((err) => {
        dispatch({ type: "ERROR_UPLOADING_COMPANY_CERT", err });
      });
  };
};

export const reviewCompanyAction = (company) => {
  return (dispatch, getState) => {
    console.log("this is my company", company);
    dispatch({ type: "REVIEW_THIS_COMPANY", company });
  };
};

export const closeReviewCompany = () => {
  return (dispatch, getState) => {
    //console.log('this is my company', company);
    dispatch({ type: "CLOSE_REVIEW_WINDOW" });
  };
};
