import authReducer from './authReducer';
import companyReducer from './companyReducer';
import coursesReducer from './coursesReducer';
import brandReducer from './brandReducer';
import technologyReducer from './technologyReducer';
import promoReducer from './promoReducer';
import repReducer from './repReducer';
import quizReducer from './quizReducer';
import staffReducer from './staffReducer';
import mailReducer from './mailReducer';
import resourceReducer from './resourceReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  promos: promoReducer,
  company: companyReducer,
  brand: brandReducer,
  technology: technologyReducer,
  reps: repReducer,
  courses: coursesReducer,
  quiz: quizReducer,
  staff: staffReducer,
  mail: mailReducer,
  resources: resourceReducer,
});

export default rootReducer;
