const initState = {
  rep: [],
  repError: null,
  repMessage: null,
  repMessagePopUp: false,
  repErrorPopup: false,
  loadingRep: true,
};

const repReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_REP_POPUPS':
      return {
        ...state,
        repError: null,
        repMessage: null,
        repMessagePopUp: false,
        repErrorPopup: false,
      };
    case 'REP_FOUND':
      return {
        ...state,
        rep: [...state.rep, action.rep],
      };
    case 'DONE_SEARCHING_FOR_REPS':
      return {
        ...state,
        loadingRep: false,
      };
    case 'NO_REP_WAS_FOUND':
      return {
        ...state,
        repError: 'No rep was found with that ID',
        repErrorPopup: true,
      };
    case 'ERROR_SEARCHING_FOR_REP':
      return {
        ...state,
        repError: action.err.message,
        repErrorPopup: true,
      };

    default:
      return state;
  }
};

export default repReducer;
