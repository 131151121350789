const initState = {
  companyError: null,
  companyMessage: null,
  companyLogo: "",
  loadedLogo: false,
  companyPopUp: false,
  companyErrorPopup: false,
  loadedCompanies: [],
  companyNames: [],
  reviewCompany: null,
  reviewCompanyLoaded: false,
  reviewModal: false,
  companyUpdated: false,
};

const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case "COMPANY_HAS_BEEN_UPDATED":
      return {
        ...state,
        companyError: null,
        companyMessage: "Company has been updated",
        companyPopUp: true,
        companyUpdated: true,
      };
    case "ERROR_UPDATING_COMPANY":
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: "Error when updating the company",
      };
    case "ERROR_SEARCHING_FOR_NEW_COMPANY":
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: "Error when search for the company",
      };
    case "COMPANY_LOGO_UPDATED":
      return {
        ...state,
        companyError: null,
        companyMessage: "Company Logo has been uploaded",
      };
    case "COMPANY_LOGO_FAILED_TO_UPLOAD":
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: "Error uploadeding companies logo",
      };
    case "LOGO_WAS_FOUND":
      //console.log('this is my action ', action)
      return {
        ...state,
        companyLogo: action.resp.url,
        loadedLogo: true,
      };
    case "MULT_LOGO_WAS_FOUND":
      //console.log('this is my action ', action)
      return {
        ...state,
        companyLogo: [...state.companyLogo, action.resp],
        loadedLogo: true,
      };
    case "NO_COMPANY_LOGO":
      //console.log('this is my action ', action)
      return {
        ...state,
        //companyLogo: action.resp.url,
        loadedLogo: true,
        companyLogo: "default",
      };
    case "COMPANY_HAS_BEEN_REVIEWED":
      return {
        ...state,
        companyError: null,
        companyMessage: "Company has been reviewed",
        companyPopUp: true,
      };
    case "CLOSE_COMPANY_POPUP":
      return {
        ...state,
        companyPopUp: false,
        companyErrorPopup: false,
        companyError: null,
        companyMessage: null,
        companyUpdated: false,
      };
    case "COMPANY_FOUND":
      //console.log("this is my action company found", action);
      if (state.loadedCompanies.length === 0) {
        return {
          ...state,
          loadedCompanies: [...state.loadedCompanies, action.clinic],
        };
      } else {
        let companyFound = state.loadedCompanies.filter(
          (lc) => lc.id === action.clinic.id
        );
        //console.log(companyFound);
        if (companyFound.length === 0) {
          return {
            ...state,
            loadedCompanies: [...state.loadedCompanies, action.clinic],
          };
        } else {
          return {
            ...state,
          };
        }
      }
    case "SINGLE_COMPANY_FOUND":
      return {
        ...state,
        ownerLoaded: false,
        companyOwner: [],
        loadedCompanies: [action.clinic],
        reloadCompany: false,
      };
    case "NO_COMPANY_WAS_FOUND":
      return {
        ...state,
        companyError: "No company was found with that ID",
        companyErrorPopup: true,
      };
    case "ERROR_SEARCHING_FOR_COMPANY":
      return {
        ...state,
        companyError: action.err.message,
        companyErrorPopup: true,

        //companyMessage: "Error uploadeding companies logo",
      };

    case "COMPANY_NAME_FOUND":
      return {
        ...state,
        companyNames: [...state.companyNames, action.clinic],
      };
    case "NEW_OWNER_CREATED":
      //console.log('i was created?');
      return {
        ...state,
      };
    case "REVIEW_THIS_COMPANY":
      return {
        ...state,
        reviewCompany: action.company,
        reviewModal: true,
      };

    case "CLOSE_REVIEW_WINDOW":
      return {
        ...state,
        reviewModal: false,
      };

    default:
      return state;
  }
};

export default companyReducer;
