export const closeStaffPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_STAFF_POPUPS' });
  };
};

export const findStaff = (companies) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    //console.log('we need to find this many companies', Array.isArray(companies));
    if (Array.isArray(companies)) {
      companies.map((c) => {
        return firestore
          .collection('users')
          .where('company', 'array-contains', c)
          .get()
          .then((snapshot) => {
            if (snapshot.isEmpty) {
              dispatch({ type: 'COMPANY_HAS_NO_EMPLOYEES' });
            } else {
              snapshot.forEach((employee) => {
                //const companyEmployees = {};

                firestore
                  .collection('companies')
                  .doc(c)
                  .get()
                  .then((company) => {
                    if (company.empty) {
                      dispatch({ type: 'NO_COMPANY_WAS_FOUND' });
                    } else {
                      let companyEmployees = { id: employee.id, data: employee.data(), company: company.data() };
                      dispatch({ type: 'FOUND_COMPANY_EMPLOYEES', companyEmployees });
                    }
                  })
                  .catch((err) => {
                    dispatch({ type: 'ERROR_SEARCHING_FOR_COMPANY', err });
                  });
              });
              //console.log('this is my staff information', companyEmployees);
            }
          })
          .catch((err) => {
            dispatch({ type: 'ERROR_SEARCHING_FOR_EMPLOYEES', err });
          });
      });
    } else {
      //console.log('just one company');
      firestore
        .collection('users')
        .where('company', 'array-contains', companies)
        .get()
        .then((snapshot) => {
          if (snapshot.isEmpty) {
            dispatch({ type: 'COMPANY_HAS_NO_EMPLOYEES' });
          } else {
            snapshot.forEach((employee) => {
              //const companyEmployees = {};

              firestore
                .collection('companies')
                .doc(employee.data().company)
                .get()
                .then((company) => {
                  if (company.empty) {
                    dispatch({ type: 'NO_COMPANY_WAS_FOUND' });
                  } else {
                    let companyEmployees = { id: employee.id, data: employee.data(), company: company.data() };
                    dispatch({ type: 'FOUND_COMPANY_EMPLOYEES', companyEmployees });
                  }
                })
                .catch((err) => {
                  dispatch({ type: 'ERROR_SEARCHING_FOR_COMPANY', err });
                });
            });
            //console.log('this is my staff information', companyEmployees);
          }
        })
        .catch((err) => {
          dispatch({ type: 'ERROR_SEARCHING_FOR_EMPLOYEES', err });
        });
    }
  };
};

export const clearStaff = () => {
  return (dispatch) => {
    dispatch({ type: 'STARTING_FRESH_STAFF_SEARCH' });
  };
};
